import axios from '@/axios/globalaxios'

export const sysRoles = data =>{
    return axios({
        url: "/tradelab/sysRole/sysRoles",
        method: 'post',
        data:data
    })
}

export const delDraftBox = data =>{
    return axios({
        url: "/tradelab/tFundFundfiles/delDraftBox",
        method: 'post',
        data:data
    })
}



